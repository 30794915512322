
import { Options, Vue, Watch } from "vue-property-decorator";
import MostDifficultQuestions from "@/components/Pages/Playground/Questions/MostDifficultQuestions.vue";
import DefaultLayout from "@/layout/DefaultLayout.vue";
import ForwardHeader from "@/components/Headers/ForwardHeader.vue";

@Options({
  components: { ForwardHeader, DefaultLayout, MostDifficultQuestions },
})
export default class MostDifficult extends Vue {
  private licenceClass = "";
  private questionId = 0;

  @Watch("$route", { immediate: true, deep: true })
  private onRouteChange(newVal: any): void {
    this.licenceClass = newVal.params.licenceClass;
    this.questionId = newVal.params.question;
  }
}
